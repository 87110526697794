import { AnimatedIconVariant } from '@uikit/components/AnimatedIcon/animatedIconVariants';
import {
  HeaderItem,
  HeaderLink,
  HeaderLinkWithIcon,
  HeaderVariantMap,
} from './types';

export const HEADER_AUDIENCES: HeaderVariantMap<HeaderLink[]> = {
  career: [],
  ecg: [],
  eeg: [],
  enercity: [
    {
      getIsActive: (pathname) => pathname.startsWith('/privatkunden'),
      isDefaultActive: true,
      title: 'Privatkund:innen',
      url: '/privatkunden',
    },
    {
      getIsActive: (pathname) => pathname.startsWith('/geschaeftskunden'),
      isDefaultActive: false,
      title: 'Geschäftskund:innen',
      url: '/geschaeftskunden',
    },
    {
      getIsActive: (pathname) => pathname.startsWith('/kommunen'),
      isDefaultActive: false,
      title: 'Kommunen',
      url: '/kommunen',
    },
  ],
  'enercity-com': [
    {
      getIsActive: (pathname) => pathname.startsWith('/privatkunden'),
      isDefaultActive: false,
      title: 'Privatkund:innen',
      url: '/privatkunden',
    },
    {
      getIsActive: (pathname) => pathname.startsWith('/geschaeftskunden'),
      isDefaultActive: false,
      title: 'Geschäftskund:innen',
      url: '/geschaeftskunden',
    },
    {
      getIsActive: (pathname) => pathname.startsWith('/kommunen'),
      isDefaultActive: false,
      title: 'Kommunen',
      url: '/kommunen',
    },
  ],
  'enercity-gwk': [
    {
      getIsActive: (pathname) => pathname.startsWith('/privatkunden'),
      isDefaultActive: false,
      title: 'Privatkund:innen',
      url: '/privatkunden',
    },
    {
      getIsActive: (pathname) => pathname.startsWith('/geschaeftskunden'),
      isDefaultActive: true,
      title: 'Geschäftskund:innen',
      url: '/geschaeftskunden',
    },
    {
      getIsActive: (pathname) => pathname.startsWith('/kommunen'),
      isDefaultActive: false,
      title: 'Kommunen',
      url: '/kommunen',
    },
  ],
  eng: [],
  esg: [],
  esol: [],
  'mein-h-gas': [],
};

export const HEADER_LOGO_LINKS: HeaderVariantMap<string> = {
  career: '/karriere',
  ecg: '/',
  eeg: '/',
  enercity: '/',
  'enercity-com': '/konzern',
  'enercity-gwk': '/geschaeftskunden',
  eng: '/',
  esg: '/',
  esol: '/',
  'mein-h-gas': '/',
};

export const HEADER_MAINHEADER_ITEMS: HeaderVariantMap<HeaderItem[]> = {
  career: [
    {
      title: 'Warum enercity?',
      url: '/karriere/warum-enercity',
    },
    {
      title: 'Arbeitswelten',
      url: '/karriere/arbeitswelten',
    },
    {
      title: 'Berufsstarter',
      url: '/karriere/berufsstarter',
    },
    {
      icon: 'action/basic-search',
      title: 'Jobsuche',
      url: '/karriere/jobsuche',
    },
  ],
  ecg: [
    {
      title: 'Contracting',
      url: '/contracting',
    },
    {
      title: 'Referenzen',
      url: '/referenzen',
    },
    {
      title: 'Abrechnung & Service',
      url: '/abrechnung-service',
    },
    {
      title: 'Unternehmen',
      links: [
        {
          icon: 'user/contracting',
          subtitle: 'Mit uns gestalten Sie die Energiewende!',
          title: 'Über uns',
          url: '/unternehmen/ueber-uns',
        },
        {
          icon: 'user/efficiency',
          subtitle: 'Aktuelle Stellenausschreibungen',
          title: 'Karriere',
          url: '/unternehmen/karriere',
        },
        {
          icon: 'user/press',
          subtitle: 'Aktuelle Meldungen',
          title: 'Presse',
          url: '/unternehmen/presse',
        },
      ],
    },
  ],
  eeg: [
    {
      title: 'Wind',
      links: [
        {
          icon: 'user/together',
          subtitle: 'Gemeinsam machen wir Ihre Kommune grün.',
          title: 'Kommunen',
          url: '/wind/kommunen',
        },
        {
          icon: 'user/hanover-region',
          subtitle: 'Ihr Grundstück trägt zur Energiewende bei.',
          title: 'Flächeneigentümer',
          url: '/wind/flaecheneigentuemer',
        },
        {
          icon: 'user/infothek',
          subtitle: 'Mythen & Fakten rund um Windenergie.',
          title: 'Infothek',
          url: '/wind/infothek',
        },
      ],
    },
    {
      title: 'Photovoltaik',
      links: [
        {
          icon: 'user/together',
          subtitle: 'Gemeinsam machen wir Ihre Kommune grün.',
          title: 'Kommunen',
          url: '/photovoltaik/kommunen',
        },
        {
          icon: 'user/hanover-region',
          subtitle: 'Ihr Grundstück trägt zur Energiewende bei.',
          title: 'Flächeneigentümer',
          url: '/photovoltaik/flaecheneigentuemer',
        },
        {
          icon: 'user/infothek',
          subtitle: 'Fakten rund um Solarenergie',
          title: 'Infothek',
          url: '/photovoltaik/infothek',
        },
      ],
    },
    {
      title: 'Referenzen',
      url: '/referenzen',
    },
    {
      title: 'Unternehmen',
      links: [
        {
          icon: 'user/enercity-e',
          subtitle: 'Die enercity Erneuerbare GmbH',
          title: 'Über uns',
          url: '/ueber-uns',
        },
        {
          icon: 'user/rocket',
          subtitle: 'Aktuelle Stellenausschreibungen',
          title: 'Karriere',
          url: '/karriere',
        },
        {
          icon: 'user/press',
          subtitle: 'Neuigkeiten über enercity Erneuerbare',
          title: 'Presse',
          url: '/presse',
        },
      ],
    },
  ],
  enercity: [
    {
      title: 'Produkte',
      links: [
        {
          animatedIcon: AnimatedIconVariant.Lightning,
          icon: 'user/lightning',
          subtitle: 'Attraktive Stromtarife für ganz Deutschland.',
          title: 'Strom',
          url: '/privatkunden/produkte/strom',
        },
        {
          animatedIcon: AnimatedIconVariant.Fire,
          icon: 'user/energy-fire',
          subtitle: 'Attraktive Gastarife für ganz Deutschland.',
          title: 'Gas',
          url: '/privatkunden/produkte/gas',
        },
        {
          animatedIcon: AnimatedIconVariant.Solar,
          icon: 'user/sun',
          subtitle: 'Stromkosten senken und unabhängiger sein.',
          title: 'Solar',
          url: '/privatkunden/produkte/solar',
        },
        {
          animatedIcon: AnimatedIconVariant.Heat,
          icon: 'user/heat',
          subtitle: 'Behagliche Wärme für Ihr Zuhause.',
          title: 'Wärme',
          url: '/privatkunden/produkte/waerme',
        },
        {
          animatedIcon: AnimatedIconVariant.Plug,
          icon: 'user/energy-plug',
          subtitle: 'Fahren Sie mit enercity auf der Überholspur.',
          title: 'Elektromobilität',
          url: '/privatkunden/produkte/elektromobilitaet',
        },
        {
          animatedIcon: AnimatedIconVariant.Smarthome,
          icon: 'user/smarthome',
          subtitle: 'Intelligent heizen und Energie sparen',
          title: 'SmartHome',
          url: '/privatkunden/produkte/smarthome',
        },
        {
          animatedIcon: AnimatedIconVariant.WaterDrop,
          icon: 'user/water-drops',
          subtitle: 'Konstant gute Trinkwasserqualität zu fairen Preisen.',
          title: 'Wasser',
          url: '/privatkunden/produkte/wasser',
        },
        {
          animatedIcon: AnimatedIconVariant.HouseConnection,
          icon: 'user/house-connection',
          subtitle: 'Umfassende Beratung rund um den Hausanschluss.',
          title: 'Hausanschluss',
          url: '/privatkunden/produkte/hausanschluss',
        },
        {
          animatedIcon: AnimatedIconVariant.SurFon,
          icon: 'user/surf-fon',
          subtitle: 'Surf&Fon Tarife — schnelles Internet mit Glasfaser',
          title: 'Telekommunikation',
          url: '/privatkunden/produkte/telekommunikation',
        },
      ],
    },
    {
      title: 'Services',
      links: [
        {
          animatedIcon: AnimatedIconVariant.Faq,
          icon: 'user/faq',
          subtitle: 'Alles rund um das Thema Energie zusammengefasst.',
          title: 'FAQ - Fragen & Antworten',
          url: 'https://helpcenter.enercity.de/hc/de',
        },
        {
          animatedIcon: AnimatedIconVariant.ContractAmendents,
          icon: 'user/contract-amendents',
          subtitle: 'Informationen rund um Ihren Umzug, Anbieterwechsel & Co.',
          title: 'Vertragsänderung',
          url: '/privatkunden/service/vertragsaenderung',
        },
        {
          animatedIcon: AnimatedIconVariant.LightBulb,
          icon: 'user/light-bulb',
          subtitle: 'Behalten Sie Ihren Energieverbrauch im Griff.',
          title: 'Energie sparen',
          url: '/privatkunden/service/energiesparen',
        },
        {
          animatedIcon: AnimatedIconVariant.Home,
          icon: 'user/home',
          subtitle: 'Information und Beantragung.',
          title: 'Energieausweis',
          url: '/privatkunden/service/energieausweis',
        },
        {
          animatedIcon: AnimatedIconVariant.ElectricMeter,
          icon: 'user/electric-meter',
          subtitle:
            'Hier können Sie ihren Zählerstand mitteilen und Ihren Abschlag anpassen.',
          title: 'Zählerstand abgeben und Abschlag ändern',
          url: 'https://helpcenter.enercity.de/hc/de/articles/115004266629-Wie-teile-ich-enercity-meinen-Zählerstand-mit',
        },
        {
          animatedIcon: AnimatedIconVariant.IBAN,
          icon: 'user/iban',
          subtitle:
            'Sorgenfrei ein Sepa-Mandat erteilen oder Bankverbindung ändern? Jetzt hier möglich.',
          title: 'Bankverbindung mitteilen',
          url: '/login',
        },
      ],
    },
    {
      title: 'Vorteile',
      url: '/vorteile',
    },
    {
      title: 'Magazin',
      url: '/magazin',
    },
    {
      title: 'Karriere',
      url: '/karriere',
    },
  ],
  'enercity-com': [
    {
      title: 'Konzern',
      links: [
        {
          icon: 'user/company',
          title: 'Unser Profil',
          url: '/konzern/profil',
        },
        {
          icon: 'user/care',
          title: 'Leitbild & Strategie',
          url: '/konzern/leitbild-strategie',
        },
        {
          icon: 'user/together',
          title: 'Management',
          url: '/konzern/management',
        },
        {
          icon: 'user/connection',
          title: 'Konzernstruktur',
          url: '/konzern/konzernstruktur',
        },
        {
          icon: 'user/calendar',
          title: 'Historie',
          url: '/konzern/historie',
        },
      ],
    },
    {
      title: 'Newsroom',
      url: '/newsroom',
    },
    {
      title: 'Investor Relations',
      url: '/konzern/investor-relations',
    },
    {
      title: 'Nachhaltigkeit',
      url: '/konzern/nachhaltigkeit',
    },
    {
      title: 'Wärmewende',
      url: '/waermewende',
    },
  ],
  'enercity-gwk': [
    {
      title: 'Produkte',
      links: [
        {
          animatedIcon: AnimatedIconVariant.Lightning,
          icon: 'user/lightning',
          subtitle: 'Attraktive Stromtarife für Ihr Gewerbe.',
          title: 'Strom',
          url: '/geschaeftskunden/produkte/strom',
        },
        {
          animatedIcon: AnimatedIconVariant.Fire,
          icon: 'user/energy-fire',
          subtitle: 'Attraktive Gastarife für Ihr Gewerbe.',
          title: 'Gas',
          url: '/geschaeftskunden/produkte/gas',
        },
        {
          animatedIcon: AnimatedIconVariant.Heat,
          icon: 'user/heat',
          subtitle: 'Grüne Wärme in Ihren Geschäftsräumen.',
          title: 'Wärme',
          url: '/geschaeftskunden/produkte/waerme',
        },
        {
          animatedIcon: AnimatedIconVariant.Plug,
          icon: 'user/energy-plug',
          subtitle: 'Fahren Sie mit enercity auf der Überholspur.',
          title: 'Elektromobilität',
          url: '/geschaeftskunden/produkte/elektromobilitaet',
        },
        {
          animatedIcon: AnimatedIconVariant.Solar,
          icon: 'user/solar',
          isInternalUrl: false,
          subtitle: 'Energie direkt vom Dach.',
          title: 'Solar',
          url: 'https://www.enercity-solution.de',
        },
        {
          animatedIcon: AnimatedIconVariant.WaterDrop,
          icon: 'user/water-drops',
          subtitle: 'Konstant gute Trinkwasserqualität zu fairen Preisen.',
          title: 'Wasser',
          url: '/geschaeftskunden/produkte/wasser',
        },
        {
          animatedIcon: AnimatedIconVariant.EnergyGasCircle,
          icon: 'user/energy-gas-circle',
          isInternalUrl: false,
          subtitle: 'Nachhaltige Wärme- und Kältelösungen.',
          title: 'Contracting',
          url: 'https://www.enercity-contracting.de',
        },
        {
          animatedIcon: AnimatedIconVariant.HouseConnection,
          icon: 'user/house-connection',
          subtitle:
            'Schließen Sie Ihr Geschäftsgebäude an die Strom-, Gas- und Wasserversorgung an.',
          title: 'Objektanschluss',
          url: '/geschaeftskunden/produkte/objektanschluss',
        },
        {
          animatedIcon: AnimatedIconVariant.Profit,
          icon: 'user/profit',
          subtitle: 'Ihre Quelle für Zusatzerlöse.',
          title: 'Direktvermarktung',
          url: '/geschaeftskunden/produkte/direktvermarktung',
        },
        {
          animatedIcon: AnimatedIconVariant.AlarmSensor,
          icon: 'user/alarm-sensor',
          subtitle: 'Intelligente Lösungen für Ihr Unternehmen.',
          title: 'SmartBusiness',
          url: '/geschaeftskunden/produkte/smartbusiness',
        },
        {
          animatedIcon: AnimatedIconVariant.Training,
          icon: 'user/training',
          subtitle: 'Praxisnahe Aus- und Weiterbildungen.',
          title: 'Schulungsangebot',
          url: '/geschaeftskunden/produkte/schulungscenter',
        },
        {
          animatedIcon: AnimatedIconVariant.Efficiency,
          icon: 'user/efficiency',
          subtitle:
            'Werden Sie klimafreundlich, klimaneutral oder klimapositiv!',
          title: 'Energieeffizienz',
          url: '/geschaeftskunden/produkte/energieeffizienz',
        },
        {
          animatedIcon: AnimatedIconVariant.DangerElectricity,
          icon: 'user/danger-electricity',
          subtitle: 'Energielösungen für den Betrieb Ihrer Anlagen.',
          title: 'Netzdienstleistungen',
          url: '/geschaeftskunden/produkte/netzdienstleistungen',
        },
        {
          animatedIcon: AnimatedIconVariant.StreetLight,
          icon: 'user/street-light',
          subtitle: 'Außenbeleuchtung aus einer Hand.',
          title: 'Beleuchtungsanlagen',
          url: '/geschaeftskunden/produkte/beleuchtung',
        },
      ],
    },
    {
      title: 'Services',
      links: [
        {
          animatedIcon: AnimatedIconVariant.MovingTruck,
          icon: 'user/moving-truck',
          subtitle: 'Melden Sie uns Ihren Geschäftsumzug.',
          title: 'Umzug',
          url: '/geschaeftskunden/service/umzug',
        },
        {
          animatedIcon: AnimatedIconVariant.Coin,
          icon: 'user/coin-50',
          subtitle: 'SEPA-Lastschrift, Abschläge oder Zahlungsschwierigkeiten.',
          title: 'Zahlung',
          url: '/geschaeftskunden/service/zahlung',
        },
      ],
    },
  ],
  eng: [
    {
      title: 'Zähler',
      links: [
        {
          subtitle: 'Jetzt einreichen',
          title: 'Zählerstand melden',
          url: '/zaehler/zaehlerstand',
        },
        {
          subtitle: 'Austausch des Geräts',
          title: 'Zählerwechsel',
          url: '/zaehler/zaehlerwechsel',
        },
        {
          subtitle: 'Messdaten & Energieausweis',
          title: 'Zählerservices',
          url: '/zaehler/zaehlerservices',
        },
        {
          subtitle: 'Jetzt ermitteln',
          title: 'Brennwert Gas',
          url: '/zaehler/brennwertermittlung',
        },
      ],
    },
    {
      title: 'Anschließen',
      links: [
        {
          subtitle: 'Anschließen & trennen',
          title: 'Netzanschluss',
          url: '/anschliessen/netzanschluss',
        },
        {
          subtitle: 'Jetzt anmelden',
          title: 'Ladeeinrichtung',
          url: '/anschliessen/ladesaeule',
        },
        {
          subtitle: 'Betrieb finden',
          title: 'Installateursuche',
          url: '/anschliessen/installateur-suchen',
        },
        {
          subtitle: 'Netzgebiet',
          title: 'Leitungsnetzauskunft',
          url: '/anschliessen/leitungsnetzauskunft',
        },
        {
          subtitle: 'Jetzt anmelden',
          title: 'Kraftwerkneuanschluss',
          url: '/anschliessen/kraftwerkneuanschluss',
        },
      ],
    },
    {
      title: 'Einspeisen',
      links: [
        {
          subtitle: 'Anmeldung neuer Erzeugungsanlagen',
          title: 'Neuanlagen',
          url: '/einspeisen/neuanlagen',
        },
        {
          subtitle: 'Änderung an bestehenden Anlagen',
          title: 'Bestandsanlagen',
          url: '/einspeisen/bestandsanlagen',
        },
      ],
    },
    {
      title: 'Partner',
      links: [
        {
          subtitle: 'Auftrag & Verzeichnis',
          title: 'Installateure',
          url: '/partner/installateure',
        },
        {
          subtitle: 'Netzentgelte & Formulare',
          title: 'Lieferanten',
          url: '/partner/lieferanten',
        },
        {
          subtitle: 'Netzentgelte & Formulare',
          title: 'Netznutzungskunden',
          url: '/partner/netznutzungskunden',
        },
        {
          subtitle: 'Formulare',
          title: 'Messstellenbetreiber',
          url: '/partner/messstellenbetreiber',
        },
        {
          subtitle: 'Formular und Hinweise',
          title: 'Schalthandlungen beantragen',
          url: '/partner/schalthandlungen',
        },
      ],
    },
    {
      title: 'Über uns',
      links: [
        {
          subtitle: 'Porträt',
          title: 'Unternehmen',
          url: '/ueber-uns/unternehmen',
        },
        {
          subtitle: 'Aktuelle Jobs & Berufsstarter',
          title: 'Karriere',
          url: '/ueber-uns/karriere',
        },
        {
          subtitle: 'Aktuelle Meldungen',
          title: 'Presse',
          url: '/ueber-uns/presse',
        },
        {
          subtitle: 'Veröffentlichungen & Verlustenergie',
          title: 'Netzinformationen',
          url: '/ueber-uns/netzinformationen',
        },
      ],
    },
  ],
  esg: [
    {
      title: 'Produkte',
      url: '/produkte',
    },
    {
      title: 'Services',
      url: '/services',
    },
    {
      title: 'Unternehmen',
      url: '/unternehmen',
    },
  ],
  esol: [
    {
      title: 'Businesskunden',
      url: '/businesskunden',
    },
    {
      isInternalUrl: false,
      title: 'Privatkunden',
      url: 'https://www.enercity.de/privatkunden/produkte/solar',
    },
    {
      title: 'Karriere',
      url: '/karriere',
    },
  ],
  'mein-h-gas': [
    {
      title: 'Umstellung',
      url: '/umstellung',
    },
    {
      title: 'Informationen',
      url: '/informationen',
    },
    {
      title: 'Förderung',
      url: '/foerderung',
    },
    {
      title: 'Downloads',
      url: '/downloads',
    },
  ],
};

export const HEADER_MAINHEADER_LINKS: HeaderVariantMap<HeaderLinkWithIcon[]> = {
  career: [],
  ecg: [],
  eeg: [
    {
      icon: 'action/phone',
      title: 'Kontakt',
      url: '/kontakt',
    },
  ],
  enercity: [],
  'enercity-com': [
    {
      icon: 'action/phone',
      title: 'Kontakt',
      url: '/kontakt',
    },
  ],
  'enercity-gwk': [],
  eng: [
    {
      icon: 'user/worldwide',
      title: 'Sprachen',
      url: '/sprachen',
    },
    {
      icon: 'action/phone',
      title: 'Kontakt',
      url: '/kontakt',
    },
  ],
  esg: [
    {
      icon: 'action/phone',
      title: 'Kontakt',
      url: '/kontakt',
    },
  ],
  esol: [
    {
      icon: 'action/phone',
      title: 'Kontakt',
      url: '/kontakt',
    },
  ],
  'mein-h-gas': [
    {
      icon: 'user/worldwide',
      title: 'Sprachen',
      url: '/sprachen',
    },
    {
      icon: 'action/phone',
      title: 'Kontakt',
      url: '/kontakt',
    },
  ],
};

export const HEADER_PREHEADER_LINKS: HeaderVariantMap<HeaderLink[]> = {
  career: [],
  ecg: [
    {
      title: 'Leitungsauskunft',
      url: '/leitungsauskunft',
    },
    {
      title: 'Stadtbeleuchtung Bremerhaven',
      url: '/bremerhaven',
    },
    {
      title: 'Kontakt',
      url: '/kontakt',
    },
  ],
  eeg: [],
  enercity: [
    {
      title: 'Über uns',
      url: 'https://www.enercity.com',
    },
    {
      title: 'Kontakt',
      url: '/kontakt',
    },
  ],
  'enercity-com': [],
  'enercity-gwk': [
    {
      title: 'Über uns',
      url: '/unternehmen',
    },
    {
      title: 'Kontakt',
      url: '/geschaeftskunden/kontakt',
    },
  ],
  eng: [
    {
      title: 'Zählerstand melden',
      url: '/zaehler/zaehlerstand',
    },
  ],
  esg: [],
  esol: [],
  'mein-h-gas': [
    {
      title: 'Vertragsinstallateure & Schornsteinfeger',
      url: '/vertragsinstallateure-schornsteinfeger',
    },
  ],
};
